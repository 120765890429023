@import 'bootstrap/dist/css/bootstrap.min.css';

@font-face {
	font-family: Blogger;
	src: url("../src/assets/fonts/Blogger_Sans.otf");
}

@font-face {
	font-family: Blogger-bold;
	src: url("../src/assets/fonts/Blogger_Sans-Bold.otf");
}

html,
body {
	width: 100%;
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: "Blogger";
	letter-spacing: 1px;
}

.first-color {
	background-color: #dfe5f3;
}

.second-color {
	background-color: #176677;
}

.width-25 {
	width: 25%;
}

.width-60 {
	width: 60%;
}

.width-80 {
	width: 80%;
}

.width-100 {
	width: 100%;
}

.margin-bottom-0 {
	margin-bottom: 0 !important;
}

button {
	background-color: #58b399d3 !important;
	outline: none;
	border: none;
	transition: 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

button:hover {
	background-color: #3b7a68 !important;
	color: whitesmoke;
	transform: scale(1.05);
}

nav {
	font-size: 1.2em;
}

a {
	text-decoration: none;
}

.breadcrumb-item a:hover {
	text-decoration: none;
}

h2 {
	font-weight: bold;
}

p {
	font-size: 1.2em;
}

h3 {
	font-size: 2em;
}

h2 {
	font-family: Blogger-bold;
	font-size: 2.2em;
	font-weight: bold;
	letter-spacing: 3px;
}

.navigation-row {
	align-items: center;
}

.navigation-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.navbar {
	background-color: #0d0d0d;
	margin: 0 auto;
	position: fixed;
	align-items: center;
	justify-content: space-around;
	box-sizing: border-box;
	width: 100%;
	z-index: 1
}

.breadcrumb {
	margin-bottom: 0;
}

.icons-container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5em;
	font-size: 2em;
	/* Adjust icon size */
	color: rgb(255, 255, 255);
	line-height: 0;
}

.navbar-brand {
	display: flex;
	align-items: center;
}

.navbar-nav {
	flex-direction: row;
}

.navbar-toggler {
	border: none;
}

.navbar-toggler-icon {
	background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-list" viewBox="0 0 16 16"><path d="M1 3.5A.5.5 0 0 1 1.5 3h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1zM1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1zM1 12.5A.5.5 0 0 1 1.5 12h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1z"/></svg>');
}

/* Apply transitions on base state */
a:link,
a:visited {
	color: inherit;
	text-decoration: none;
	transition: color 0.3s ease, transform 0.5s ease;
	/* Ensure transform is also transitioned */
}

a:hover {
	color: #A0AEC0;
	transform: scale(1.1);
	/* Scale up on hover */
}

a:active {
	color: inherit;
}

.hamburger-menu {
	cursor: pointer;
	position: relative;
	z-index: 2;
}

.hamburger-menu .my-links {
	opacity: 0;
	transform: translateY(-20px);
	visibility: hidden;
	position: absolute;
	top: 50px;
	left: -20px;
	background-color: #0d0d0d;
	padding: 1rem;
	width: 175px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
	border-radius: 0 0 5px 0;
	transition: opacity 0.5s ease, transform 0.5s ease, visibility 0.5s ease;
}

.hamburger-menu .my-links.show {
	opacity: 1;
	transform: translateY(0);
	visibility: visible;
}

.hamburger-menu .my-links.hide {
	opacity: 0;
	transform: translateY(-20px);
	visibility: hidden;
}

/* Ensure links are styled properly */
.hamburger-menu .my-links a {
	display: block;
	line-height: 1.5;
	color: white;
	text-decoration: none;
	transition: color 0.3s ease;
	font-size: 0.6em;
}

.hamburger-menu .my-links a:hover {
	color: #A0AEC0;
}

/* Display the links when the menu is open */
.hamburger-menu .my-links {
	display: block;
}

.hamburger-menu {
	display: none;
}

.visible-on-mobile-logo {
	display: none;
}

.main-logo {
	display: block;
}

::-webkit-scrollbar {
	display: none;
}

.section-container {
	width: 80%;
}

.contact-form-container {
	width: 60%;
}

.footer-container {
	width: 60%;
}

.page-image-container {
	max-height: 450px;
	margin-right: 25px;
	margin-bottom: 2px;
	float: left;
	display: block;
	object-fit: cover;
	border-radius: 50px 0 50px 0;
}

img {
	box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
}

p::first-letter {
	margin-left: 30px;
}

@media (max-width: 1460px) {
	.footer-container {
		width: 80%;
	}

	.page-image-container {
		float: none;
		width: 100%;
		margin-right: 0;
		margin-bottom: 12px;
		border-radius: 5px;
	}
}

@media (max-width: 1090px) {
	.image-container {
		flex-direction: column !important;
	}

	.image-container img {
		margin-top: 45px;
		width: 100%;
	}

	.image-container p {
		text-align: justify;
	}

	.pr-5 {
		padding-right: 12px !important;
	}

	.footer-container {
		width: 60%;
	}

	.footer-container {
		width: 90%;
		font-size: 0.8em;
	}
}

@media (max-width: 767px) {
	.breadcrumb {
		font-size: 0.85em;
	}

	.navigation-row {
		display: none;
	}

	.hamburger-menu {
		display: block;
	}

	.navigation-row {
		display: none;
	}

	.navigation-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.breadcrumb {
		display: none;
		/* Hide breadcrumb on mobile */
	}

	.hamburger-menu {
		display: block;
	}

	.visible-on-mobile-logo {
		display: block;
	}

	.main-logo {
		display: none;
	}

	.navbar {
		justify-content: space-between;
		padding: 10px 20px;
	}

	.section-container {
		width: 90%;
	}

	.contact-form-container {
		width: 90%;
	}

	.contact-form-container .row {
		margin-bottom: 9px !important;
	}

	.text-align-justify p {
		text-align: justify;
	}
}